<template>
  <div class="relative border border-gray-600 mb-4" @click="$emit('click')">
    <div
      class="absolute top-0 left-0 leading-5 px-1 ml-3 text-xs text-gray-800
      bg-white transform -translate-y-1/2"
    >
      {{ label }}
    </div>
    <div class="text-gray-800" :class="[contentClass, {'p-4': spacing}]">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: false,
    },
    spacing: {
      type: Boolean,
      default: true,
    },
    contentClass: {
      type: String,
      default: null,
    },
  },
};
</script>
