<template>
  <Card content>
    <div class="mb-6 text-xl font-light leading-5 text-primary-500">
      {{ $t('memberData') }}
    </div>
    <div class="flex">
      <div class="w-1/2 mr-2">
        <SelectField
          v-model="newContract.customer.title"
          :label="$t('title')"
          floating
        >
          <option :value="null">–</option>
          <option value="dr">{{ $t('dr') }}</option>
          <option value="prof">{{ $t('prof') }}</option>
          <option value="dipl-ing">{{ $t('diplIng') }}</option>
        </SelectField>
      </div>
      <div class="w-1/2 ml-2" />
    </div>
    <div class="flex">
      <div class="w-1/2 mr-2">
        <TextField
          v-model="newContract.customer.firstname"
          :label="$t('firstName')"
          floating
          required
        />
      </div>
      <div class="w-1/2 ml-2">
        <TextField
          v-model="newContract.customer.lastname"
          :label="$t('lastName')"
          floating
          required
        />
      </div>
    </div>
    <div class="flex">
      <div class="w-1/2 mr-2">
        <DatePicker
          v-model="newContract.customer.birthday"
          :label="$t('birthday')"
          floating
          required
        />
      </div>
      <div class="w-1/2 ml-2">
        <SelectField
          v-model="newContract.customer.sex"
          :label="$t('sex')"
          floating
          required
        >
          <option :value="1">{{ $t('female') }}</option>
          <option :value="0">{{ $t('male') }}</option>
        </SelectField>
      </div>
    </div>
    <div class="flex">
      <div class="w-1/2 mr-2">
        <TextField
          v-model="newContract.customer.street"
          :label="$t('street')"
          floating
          required
        />
      </div>
      <div class="w-1/2 ml-2">
        <TextField
          v-model="newContract.customer.houseNumber"
          :label="$t('houseNumber')"
          floating
          inputmode="numeric"
          class="w-full"
          required
        />
      </div>
    </div>
    <div v-if="countryIsAustria" class="flex">
      <div class="w-1/2 mr-2">
        <TextField
          v-model="newContract.customer.streetAddition1"
          :label="$t('stairs')"
          floating
        />
      </div>
      <div class="w-1/2 ml-2">
        <TextField
          v-model="newContract.customer.streetAddition2"
          :label="$t('door')"
          floating
          inputmode="numeric"
          class="w-full"
          :required="streetAddition1IsNotEmptyAT"
        />
      </div>
    </div>
    <div class="flex">
      <div class="w-1/2 mr-2">
        <TextField
          v-model="newContract.customer.postcode"
          :label="$t('postcode')"
          floating
          inputmode="numeric"
          class="w-full"
          required
        />
      </div>
      <div class="w-1/2 ml-2">
        <TextField
          v-model="newContract.customer.city"
          :label="$t('city')"
          floating
          class="w-full"
          required
        />
      </div>
    </div>
    <div class="flex">
      <div class="w-1/2 mr-2">
        <TextField
          v-model="newContract.customer.addition"
          :label="$t('addressAddition')"
          floating
        />
      </div>
      <div class="w-1/2 ml-2">
        <SelectField
          v-model="newContract.customer.country"
          :label="$t('country')"
          floating
          required
        >
          <option v-for="country in $t('countries')" :key="country[1]" :value="country[0]">
            {{ country[1] }}
          </option>
        </SelectField>
      </div>
    </div>
    <TextField
      v-model="newContract.customer.email"
      :label="$t('email')"
      inputmode="email"
      type="email"
      floating
      required
    />
    <div class="relative">
      <div class="flex">
        <div class="flex w-1/6 mr-2">
          <SelectField
            v-model="newContract.customer.phoneCountryCode"
            class="w-full"
            label="LKZ"
            floating
            required
          >
            <option
              v-for="country in sortedCountries"
              :key="`phoneCountryCode_${country[0]}`"
              :value="country[0] === 0 ? 0 : `+${country[2].code}`"
              :disabled="country[0] === 0"
            >
              <template v-if="country[0] === 0">{{ country[1] }}</template>
              <template v-else>{{ country[2].label }} +{{ country[2].code }}</template>
            </option>
          </SelectField>
        </div>
        <div class="flex w-1/2 mr-2">
          <TextField
            v-model="newContract.customer.phone"
            :label="$t('phone')"
            inputmode="tel"
            floating
            class="w-full"
            required
          />
        </div>
        <div class="flex w-1/3 ml-2">
          <SelectField
            v-model="newContract.phoneType"
            class="w-full"
            @input="(value) => value === 'mobile' ? newContract.phoneWorkType = 'landline' : newContract.phoneWorkType = 'mobile'"
          >
            <option value="mobile">{{ $t('mobile') }}</option>
            <option value="landline">{{ $t('landline') }}</option>
          </SelectField>
        </div>
      </div>
    </div>
    <div v-if="!newContract.customer.age || newContract.customer.age >= 18">
      <CheckBox
        v-model="newContract.customer.showLegalGuardian"
        class="mb-4"
      >
        {{ $t('needsLegalGuardian') }}
      </CheckBox>
    </div>
    <TextField
      v-if="newContract.customer.showLegalGuardian || (newContract.customer.age && newContract.customer.age < 18)"
      v-model="newContract.customer.legalGuardian"
      :label="newContract.customer.showLegalGuardian ? $t('legalGuardianAlt') : $t('legalGuardian')"
      :required="newContract.customer.age && newContract.customer.age < 18"
      floating
      class="mb-6"
    />
  </Card>
</template>

<script>
import { differenceInYears } from 'date-fns';

export default {
  computed: {
    newContract() {
      return this.$store.state.contract.newContract;
    },
    sortedCountries() {
      const primeCountries = this.$t('countries')
        .filter((country) => ([1, 4, 9].includes(country[0])))
        .sort((a, b) => a[0] - b[0]);
      const sortedCountries = this.$t('countries')
        .filter((country) => (![1, 4, 9].includes(country[0])))
        .sort((a, b) => {
          if (a[1] < b[1]) { return -1; }
          if (a[1] > b[1]) { return 1; }
          return 0;
        });
      return [...primeCountries, [0, '----------'], ...sortedCountries];
    },
    countryIsAustria() {
      const countryId = parseInt(this.newContract.customer.country, 10);
      return countryId === 4; // Id 4 is Austria
    },
    /**
     * If the country is Austria AND streetAddition1 is not empty
     * streetAddition2 must be set as well.
     * @returns {boolean}
     */
    streetAddition1IsNotEmptyAT() {
      if (!this.countryIsAustria) {
        return false;
      }
      return this.newContract.customer.streetAddition1 !== null
             && this.newContract.customer.streetAddition1 !== '';
    },
  },

  watch: {
    'newContract.customer.birthday': {
      handler(newVal) {
        const today = new Date();
        const birth = new Date(newVal);
        this.newContract.customer.age = differenceInYears(today, birth);
      },
    },
  },
};
</script>
