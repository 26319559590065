<template>
  <Card v-if="contract" content>
    <div class="mb-6 text-xl font-light leading-5 text-primary-500">
      {{ $t('contractData') }}
    </div>
    <TextBox :label="$t('contractPackage')">
      <div class="flex justify-between">
        <div>
          <div class="uppercase">
            {{ contract.name }}
          </div>
          <div class="text-xs">
            {{ contractRenewalString(selectedContractRenewal) }}
          </div>
          <div class="mt-1 text-xs mr-2">
            <span>inkl.</span>
            <span v-for="(moduleName, index) in includedModuleNames" :key="moduleName">
              {{ moduleName }}<template v-if="index !== includedModuleNames.length - 1">, </template>
            </span>
          </div>
          <div v-if="selectedModuleNames.length >= 1" class="mt-1 text-xs mr-2">
            <span>zzgl.</span>
            <span
              v-for="(moduleObj, index) in selectedModuleNames"
              :key="moduleObj.id"
            >
              {{ moduleObj.contractProperties.name }}
              ({{ modulePriceString(moduleObj.price) }}) <template v-if="index !== selectedModuleNames.length - 1">, </template>
            </span>
          </div>
        </div>
        <div>
          <div class="text-xl">{{ $n(overallPrice, 'currency') }}</div>
          <div class="text-xs">{{ $t(accountingCycle, { n: selectedContractRenewal.accountingCycle }) }}</div>
        </div>
      </div>
      <!-- <div class="mt-4 text-xxs">
        {{ $t('sumForMonthIncludingServiceFees', {
          month: selectedContractRenewal.duration,
          sum: $n(totalContractSum, 'currency'),
        }) }}
      </div> -->
    </TextBox>
    <div class="flex">
      <div
        class="flex-1"
        :class="{
          'mr-2': configs.defaultContractBeginDay === constants.CONTRACT_BEGIN_START_OF_NEXT_MONTH,
        }"
      >
        <DatePicker
          v-model="newContract.startingConditions.beginningOfMembership"
          :label="$t('beginningOfMembership')"
          floating
          required
        />
      </div>
      <div
        v-if="configs.defaultContractBeginDay === constants.CONTRACT_BEGIN_START_OF_NEXT_MONTH"
        class="flex-1 ml-2"
      >
        <DatePicker
          v-model="newContract.startingConditions.beginningOfTraining"
          :label="$t('beginningOfTraining')"
          floating
          required
        />
      </div>
    </div>
    <!-- <div class="flex">
      <div class="w-1/2 mr-2">
        <SelectField
          v-model="newContract.paymentCycle"
          floating
          :label="$t('paymentCycle')"
          required
        >
          <option
            v-for="cycle in paymentCycles"
            :key="cycle"
            :value="cycle"
          >
            {{ $t(cycle) }}
          </option>
        </SelectField>
      </div>
      <div class="w-1/2 ml-2" />
    </div> -->
    <TextBox v-if="fees.length > 0" :label="$t('flat_rates')">
      <!-- <div
        v-for="fee in fees"
        :key="fee.id"
        v-html="$t(`repeatStrings.${repeatToString(fee.repeat, fee.repeatUnit)}`, {
          amount: $n(fee.active ? fee.default : fee.min, 'currency'),
          n: fee.repeat,
          name: fee.name,
        })"
        class="text-xs text-gray-800"
      /> -->
      <div
        v-for="fee in fees"
        :key="fee.id"
        v-html="$t('repeatString', {
          amount: $n(fee.active ? fee.default : fee.min, 'currency'),
          name: fee.name,
        })"
        class="text-xs text-gray-800"
      />
      <!-- <div
        v-html="$t('serviceFeeHalfYearly', {
          fee: $n(newContract.fees.serviceFee, 'currency')
        })"
        class="text-xs text-gray-800"
      />
      <div
        v-html="$t('entranceMediumFeeAtBeginningOfMembership', {
          fee: $n(newContract.fees.entranceMediumFee, 'currency')
        })"
        class="text-xs text-gray-800"
      /> -->
    </TextBox>
    <SelectField
      v-model="newContract.accounting.dueDate"
      :label="$t('paymentDueDate')"
      floating
      required
    >
      <option value="keyDate">{{ $t('keyDate') }}</option>
      <template v-if="selectedContractRenewal.accountingCycleUnit === 1">
        <option value="firstOfMonth">{{ $t('firstOfMonth') }}</option>
        <option value="fifteenthOfMonth">{{ $t('fifteenthOfMonth') }}</option>
      </template>
    </SelectField>
    <div
      v-if="configs.defaultContractBeginDay === constants.CONTRACT_BEGIN_START_OF_NEXT_MONTH"
      class="flex"
    >
      <div class="w-1/2 mr-2">
        <TextBox
          :label="$t('advanceUsageFee')"
        >
          {{ $n(newContract.startingConditions.preUse ? newContract.startingConditions.advanceUsageFee : 0, 'currency') }}
        </TextBox>
      </div>
      <div class="w-1/2 ml-2">
        <!-- <DatePicker
          v-model="newContract.startingConditions.dueDateForAdvanceUse"
          :label="$t('dueDateForAdvanceUse')"
          floating
        /> -->
      </div>
    </div>
    <!-- <div class="mb-8 text-xs text-gray-800">
      Bedingungen der Kündigungsfrist...
    </div> -->
  </Card>
</template>

<script>
import { repeatUnitToString, accountingCycleToString, cycleUnitsToString } from '../../../utils';
import { CONTRACT_BEGIN_TODAY, CONTRACT_BEGIN_START_OF_NEXT_MONTH } from '../../../constants';
import TextBox from '../TextBox.vue';

export default {
  components: {
    TextBox,
  },

  computed: {
    contract() {
      return this.$store.state.contract.selectedContract;
    },
    paymentCycles() {
      return ['monthly', 'yearly'];
    },
    newContract() {
      return this.$store.state.contract.newContract;
    },
    modules() {
      return this.$store.state.contract.selectedContract?.properties;
    },
    selectedModules() {
      return this.$store.state.contract.newContract.selectedModules;
    },
    selectedContractRenewal() {
      return this.$store.state.contract.selectedContractRenewal;
    },
    overallPrice() {
      let price = this.selectedContractRenewal.amount;
      if (this.modules) {
        this.modules.forEach((moduleObj) => {
          if (this.selectedModules.includes(moduleObj.id)) {
            price += moduleObj.price;
          }
        });
      }
      return Number(price.toFixed(2));
    },
    totalContractSum() {
      let price = 0;
      if (this.selectedContractRenewal.accountingCycle === 12) {
        price += Number((this.overallPrice).toFixed(2));
      } else {
        price += Number((this.overallPrice * this.selectedContractRenewal.duration).toFixed(2));
      }
      if (Array.isArray(this.newContract.fees)) {
        this.newContract.fees.forEach((fee) => {
          console.log(fee);
          price += fee.amount;
        });
      }
      if (this.newContract.startingConditions.advanceUsageFee) {
        price += Number(this.newContract.startingConditions.advanceUsageFee);
      }

      return price;
    },
    contractFields() {
      return [
        {
          name: 'beginningOfMembership',
          label: 'beginningOfMembership',
        },
        {
          name: 'beginningOfTraining',
          lang: 'beginningOfTraining',
        },
        {
          name: 'beginningOfTraining',
          lang: 'beginningOfTraining',
        },
      ];
    },
    fees() {
      return this.$store.state.contract.fees;
    },
    includedModuleNames() {
      const modules = [];
      this.contract.properties.forEach((moduleObj) => {
        if (moduleObj.price === 0) {
          modules.push(moduleObj.contractProperties.name);
        }
      });
      return modules;
    },
    selectedModuleNames() {
      const modules = [];
      this.contract.properties.forEach((moduleObj) => {
        if (this.selectedModules.includes(moduleObj.id)) {
          modules.push(moduleObj);
        }
      });
      return modules;
    },
    accountingCycle() {
      if (this.selectedContractRenewal) {
        return accountingCycleToString(
          this.selectedContractRenewal.accountingCycle,
          this.selectedContractRenewal.accountingCycleUnit,
          'sumIncludingTaxesObj',
        );
      }
      return '';
    },
    configs() {
      return this.$store.state.contract.configs;
    },
    constants() {
      return {
        CONTRACT_BEGIN_TODAY,
        CONTRACT_BEGIN_START_OF_NEXT_MONTH,
      };
    },
  },

  methods: {
    repeatToString(repeat, repeatUnit) {
      return repeatUnitToString(repeat, repeatUnit);
    },
    contractRenewalString(contractRenewal) {
      const duration = this.$tc(cycleUnitsToString(contractRenewal.durationUnit, 'durations'), contractRenewal.duration);
      const accountingCycle = this.$t(accountingCycleToString(
        contractRenewal.accountingCycle,
        contractRenewal.accountingCycleUnit,
        'accountingCycles',
      ), {
        n: contractRenewal.accountingCycle,
        amount: this.$n(contractRenewal.amount, 'currency'),
      });
      return `${duration} / ${accountingCycle}`;
    },
    modulePriceString(modulePrice) {
      const contractRenewal = this.selectedContractRenewal;
      const priceString = this.$t(accountingCycleToString(
        contractRenewal.accountingCycle,
        contractRenewal.accountingCycleUnit,
        'accountingCycles',
      ), {
        n: contractRenewal.accountingCycle,
        amount: this.$n(modulePrice, 'currency'),
      });
      return priceString;
    },
  },
};
</script>
