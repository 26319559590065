<template>
  <Card content>
    <div class="text-primary-500 font-light text-xl mb-6 leading-5">
      {{ $t('bankData') }}
    </div>
    <TextField
      v-model="newContract.accounting.accountHolderBank"
      :label="$t('accountHolder')"
      floating
      required
    />
    <div class="flex">
      <div class="w-1/2 mr-2">
        <!-- minLength 15 for Norway (NO) and maxLength 28 for Malta (MT) -->
        <TextField
          v-model="newContract.accounting.ibanBank"
          :label="$t('iban')"
          :required="paymentFieldsRequired"
          :minlength="minIbanLen"
          :maxlength="maxIbanLen"
          floating
        />
      </div>
      <div class="w-1/2 ml-2">
        <TextField
          v-model="newContract.accounting.bicBank"
          :label="$t('bic')"
          :required="paymentFieldsRequired"
          :minlength="8"
          :maxlength="11"
          floating
        />
      </div>
    </div>
    <div class="flex">
      <div class="w-full">
        <TextField
          v-model="newContract.accounting.nameBank"
          :label="$t('bank')"
          floating
          :required="paymentFieldsRequired"
        />
      </div>
    </div>
    <p class="text-gray-800 text-xs mb-4">
      {{ $t('acceptDirectDebit', {
        studio: configs.branchName,
      }) }}
    </p>
    <Signature
      v-model="newContract.signatures.accountHolder"
      label="Unterschrift des Kontoinhabers"
      id="signature_account_holder"
      required
    />
  </Card>
</template>

<script>
import paymentHelper from '@/mixins/paymentHelper';
import Signature from '../Signature.vue';

export default {
  components: {
    Signature,
  },

  mixins: [paymentHelper],

  computed: {
    newContract() {
      return this.$store.state.contract.newContract;
    },
    /**
     * @Override
     * Overriding function in paymentHelper.js mixin.
     */
    paymentFieldsRequired() {
      const methodObj = this.paymentMethods.find((method) => method.id === this.newContract.accounting.paymentMethod);
      return methodObj && methodObj.systemId === 1;
    },
  },
};
</script>
