<template>
  <SafeForm @submit.prevent="sendContract">
    <ContractDataCard class="mb-8"/>
    <MemberDataCard class="mb-8"/>
    <BankDataCard v-if="paymentFieldsRequired" class="mb-8"/>
    <Card content>
      <!-- <CheckBox v-model="newContract.acceptContact" required>
        {{ $t('acceptContact') }}
      </CheckBox> -->
      <CheckBox v-if="configs.countryId === 1" v-model="newContract.acceptInformations" id="checkbox_infos" required>
        <span>
          Die Hinweise und Informationsmöglichkeiten zur Nutzung von Solarien gem. §7 Abs. 4, der aktuellen UV-Schutzverordnung und
          zur Nutzung weiterer belehrungsbedürftiger Einrichtungen habe ich zur Kenntnis genommen.
        </span>
      </CheckBox>
      <CheckBox v-model="newContract.acceptTerms" id="checkbox_terms" required>
        <span v-html="$t('acceptTerms')"/>
      </CheckBox>
      <!-- <CheckBox v-model="newContract.acceptPrivacy" required>
        <div v-html="$t('acceptPrivacy', {
          href: '#',
        })" />
      </CheckBox> -->
      <Signature
        v-model="newContract.signatures.member"
        :label="$t('signatureMember')"
        class="mt-6"
        id="signature_member"
        required
      />
      <Signature
        v-if="newContract.customer.legalGuardian"
        v-model="newContract.signatures.legalGuardian"
        :label="$t('signatureLegalGuardian')"
        :required="!!newContract.customer.legalGuardian"
      />
      <Signature
        v-model="newContract.signatures.employee"
        :label="$t('signatureWorker')"
        id="signature_worker"
        required
      />
    </Card>
    <div class="flex flex-shrink mt-12 pb-24">
      <Button class="mr-2 w-full" to="accounting">
        {{ $t('back') }}
      </Button>
      <Button
        class="ml-2 w-full"
        :class="{
          'pointer-events-none': loading,
        }"
        primary
        type="submit"
      >
        {{ $t('submit') }}
      </Button>
    </div>
    <Portal to="Modal">
      <!-- <Modal :value="showSuccessModal">
        <div class="w-140 px-6 py-8 text-center">
          <h2 class="font-semibold text-gray-800 text-xl">{{ $t('contractSubmissionSuccess') }}</h2>
          <div class="px-24 py-16">
            <Button class="w-full mb-6" @click="toHome">
              {{ $t('toHome') }}
            </Button>
            <Button class="w-full mb-6" @click="$store.dispatch('getContractPDF', savedContract.contractId)">
              {{ $t('downloadContractAsPdf') }}
            </Button>
            <Button v-if="savedContract" class="w-full mb-6" :to="`/${$route.params.studio}/photo/${savedContract.customerId}`">
              {{ $t('addCustomerPhoto') }}
            </Button>
          </div>
        </div>
      </Modal> -->
      <Modal :value="loading">
        <div class="px-6 py-6 flex items-center justify-center">
          <LoadingCircle class="mr-4"/>
          <span class="text-gray-800">{{ $t('contractSending') }}</span>
        </div>
      </Modal>
      <Modal :value="showErrorModal">
        <div class="max-w-sm px-6 py-4">
          <div class="text-red-500 text-center">
            <Icon name="alert" font-classes="text-5xl"/>
          </div>
          <div class="w-full text-center">
            <template v-if="newContract.selectedCustomerId">
              {{ $t('cannotSaveContractForCustomerId', { id: newContract.selectedCustomerId }) }}
            </template>
            <template v-else>
              {{ $t('cannotSaveContract') }}
            </template>
          </div>
          <Button class="w-full mt-4" @click="showErrorModal = false">
            {{ $t('close') }}
          </Button>
        </div>
      </Modal>
      <Modal v-model="showAgbModal">
        <button
          class="focus:outline-none absolute top-0 right-0 mt-4 mr-4 bg-white"
          @click="showAgbModal = false"
        >
          <Icon name="close" font-classes="text-3xl"/>
        </button>
        <div class="prose px-8 pb-12 pt-8 max-h-75vh max-w-70vw overflow-y-auto">
          <div v-if="configs" v-html="configs.termsOfService"/>
        </div>
      </Modal>
    </Portal>
  </SafeForm>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import paymentHelper from '@/mixins/paymentHelper';
import TextBox from '../../../components/TextBox.vue';
import Signature from '../../../components/Signature.vue';
import ContractDataCard from '../../../components/Summary/ContractDataCard.vue';
import MemberDataCard from '../../../components/Summary/MemberDataCard.vue';
import BankDataCard from '../../../components/Summary/BankDataCard.vue';
import LoadingCircle from '../../../components/LoadingCircle.vue';

export default {
  components: {
    TextBox,
    ContractDataCard,
    MemberDataCard,
    BankDataCard,
    Signature,
    LoadingCircle,
  },

  data() {
    return {
      showSuccessModal: false,
      showErrorModal: false,
      showAgbModal: false,
    };
  },

  mixins: [paymentHelper],

  computed: {
    contract() {
      return this.$store.state.contract.selectedContract;
    },
    newContract() {
      return this.$store.state.contract.newContract;
    },
    savedContract() {
      return this.$store.state.contract.savedContract;
    },
    configs() {
      return this.$store.state.contract.configs;
    },
    loading() {
      return this.$store.state.contract.loading;
    },
    /**
     * @Override
     * Overriding function in paymentHelper.js mixin.
     */
    paymentFieldsRequired() {
      const methodObj = this.paymentMethods.find((method) => method.id === this.newContract.accounting.paymentMethod);
      return methodObj && methodObj.systemId === 1;
    },
  },

  mounted() {
    this.$nextTick(() => {
      const agbLink = document.getElementById('agbClick');
      agbLink.addEventListener('click', (e) => {
        e.preventDefault();
        this.showAgbModal = true;
      });
    });
  },

  methods: {
    sendContract() {
      this.newContract.signatures.employee.name = this.$store.state.authentication.user.name;
      this.$store.dispatch('sendContract')
        .then(() => {
          // this.showSuccessModal = true;
          this.$store.commit('resetNewContract');
          this.$store.dispatch('removeSearchResults');
          this.$router.push({ name: 'new-contract-success', params: { studio: this.$route.params.studio } });
        })
        .catch(() => {
          this.showErrorModal = true;
        });
    },
    toHome() {
      this.$store.commit('resetSavedContractData');
      this.$store.commit('resetNewContract');
      this.$store.dispatch('removeSearchResults');
      this.$router.push(`/${this.$route.params.studio}/home`);
    },
  },
};
</script>
