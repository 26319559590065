<template>
  <div>
    <TextBox
      class="cursor-pointer"
      :label="required ? `${label}*`: label"
      :id="`box_${id}`"
      @click="modalOpen = true"
    >
      <div class="relative w-full h-16 flex items-center justify-center">
        <img v-if="signature" :src="signature" class="w-auto h-full" />
        <!-- <div v-if="value.city && value.date" class="absolute bottom-0 right-0 text-xs">
          {{ $t('inCityAtDate', {
            city: value.city,
            date: $d(new Date(value.date), 'short'),
          }) }}
        </div> -->
        <div v-if="value.date" class="absolute bottom-0 right-0 text-xs">
          {{ $d(new Date(value.date), 'short') }}
        </div>
        <input
          ref="input"
          :id="`field_${id}`"
          class="w-px h-px opacity-0 absolute top-0 left-0"
          :value="signature"
          :required="required"
        />
      </div>
    </TextBox>
    <Portal to="Modal">
      <Modal v-model="modalOpen" modal-class="px-4 py-4 max-w-2xl">
        <TextBox
          class="pointer-events-auto"
          :label="label"
          :spacing="false"
          content-class="h-48 w-full"
        >
          <VueSignaturePad
            v-if="(modalOpen && !signature) || signature"
            ref="signaturePad"
            :id="`pad_${id}`"
          />
        </TextBox>
        <!-- <TextField
          :value="value.city"
          :label="$t('city')"
          @input="(val) => $emit('input', {
            city: val,
            date: value.date,
            signature: value.signature,
          })"
          floating
        /> -->
        <div class="flex flex-wrap justify-between pt-4">
          <Button text @click="onRemove">
            {{ $t('remove') }}
          </Button>
          <Button text @click="onCancel">
            {{ $t('cancel') }}
          </Button>
          <Button @click="onApprove">
            {{ $t('approve') }}
          </Button>
        </div>
      </Modal>
    </Portal>
  </div>
</template>

<script>
import { nanoid } from 'nanoid';
import TextBox from './TextBox.vue';

export default {
  components: {
    TextBox,
  },

  props: {
    label: {
      type: String,
      default: null,
    },
    value: {
      type: Object, // { city: String, date: String, signature: String }
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: () => nanoid(),
    },
  },

  data() {
    return {
      signature: null,
      modalOpen: false,
    };
  },

  watch: {
    modalOpen(newVal) {
      if (newVal && this.signature) {
        this.$nextTick(() => {
          setTimeout(() => {
            console.log(this.$refs);
            this.$refs.signaturePad.addImages([this.signature]);
          }, 200);
        });
      }
    },
  },

  methods: {
    onRemove() {
      this.$refs.signaturePad.clearSignature();
    },
    onApprove() {
      const signature = this.$refs.signaturePad.saveSignature();
      if (!signature.isEmpty) {
        this.signature = signature.data;
        this.$emit('input', {
          city: this.value.city,
          date: this.value.date,
          signature: signature.data,
        });
        this.modalOpen = false;
      }
    },
    onCancel() {
      this.signature = null;
      this.$refs.signaturePad.clearSignature();
      this.modalOpen = false;
    },
  },
};
</script>
